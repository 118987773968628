import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout/layout";
import notFound from "../resources/assets/not-found.json";
import { Player } from "@lottiefiles/react-lottie-player";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div className="flex justify-center pb-20 px-4">
        <div>
          <div className="w-3/4 md:w-1/2 mx-auto">
            <Player src={notFound} autoplay loop />
          </div>
          <p className="font-extrabold text-8xl text-center">404</p>
          <h1 className="font-extrabold text-4xl text-center">
            Page not found
          </h1>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
